@import "./global.sass"

html, body
  // background: linear-gradient(#e66465, #9198e5)
  // background-image: url(./assets/img_polygon_top.png)
  // background-color: rgba(247, 148, 29, .05)
  // background: linear-gradient(#4051b1 5.23%,#5a55ae 17.32%,#7b5fac 31.09%,#a86aa4 45.53%,#d87384 59.74%,#f18271 71.55%,#f3a469 84.23%,#f7c978 97.25%)

.layout
  background-color: #fff
  margin: auto
  height: 100vh
  max-width: 428px
  overflow-x: hidden
  overflow-y: scorll
  border: 1px solid #e6e6e6
  .header
    background-color: #fff
    position: fixed
    top: 0
  .content
    padding: 92px 36px 48px
  .footer
    background-color: #fff
    position: fixed
    bottom: 0
