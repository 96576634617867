.bg-theme {
  background: linear-gradient(
    90deg,
    #4051b1 5.23%,
    #5a55ae 17.32%,
    #7b5fac 31.09%,
    #a86aa4 45.53%,
    #d87384 59.74%,
    #f18271 71.55%,
    #f3a469 84.23%,
    #f7c978 97.25%
  );
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .headline {
    @apply font-bold text-2xl;
  }

  .btn {
    @apply py-2 px-8 tracking-widest text-2xl bg-theme cursor-pointer text-white font-bold rounded-xl transition-all hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-opacity-75;
  }
  .input {
    @apply border border-gray-400 w-full rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-opacity-75;
  }
}
